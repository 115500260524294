*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*, html {
  image-rendering: pixelated;
}

body {
  margin: 0 auto;
}

html, body {
  overflow: hidden;
  height: 100%;
}

@font-face {
  font-family: 'PunkClockType';
  src: url('../fonts/PunkClockType-Regular.woff2') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'PunkDayType';
  src: url('../fonts/PunkDayType-Regular.woff2') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'PunkMonthType';
  src: url('../fonts/PunkMonthType-Regular.woff2') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'TimesNewDay';
  src: url('../fonts/TimesNewDay-Regular.woff2') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'PunkSystemReg';
  src: url('../fonts/PunkSystem-Regular.woff2') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'PunkSystemBold';
  src: url('../fonts/PunkSystem-Bold.woff2') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'PunkSystemComp';
  src: url('../fonts/PunkSystem-Compact.woff2') format('woff');
  font-display: swap;
}

.root {
  user-drag: none;
  user-select: none;
  border: 1px solid #000000;
  box-sizing: border-box;
}

.checkered3 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #0075FF; */
  background-size: 4px 4px;
  background-position: 0 0, 2px 2px;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.85) 15%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.85) 66%, rgba(0, 0, 0, 0.85)),
          linear-gradient(45deg, rgba(0, 0, 0, 0.85) 15%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.85) 66%, rgba(0, 0, 0, 0.85));
}

::-webkit-scrollbar {
  width: 0.2em;
  background-color: none;
}
 
::-webkit-scrollbar-thumb {
  background-color: none;
}
 
::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: none;
}

input::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 20px;
  background-color: black;
}


